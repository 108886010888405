
import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";

@Component
export default class ForgotPasswordForm extends Vue {
  @Model("input", { type: String }) readonly login!: string;

  @Emit()
  input() {
    return this.localLogin;
  }

  localLogin!: string;

  constructor() {
    super();

    this.localLogin = this.login;
  }

  @Watch("localLogin")
  onLocalLoginChanged() {
    this.input();
  }

  @Watch("login")
  onLoginChanged() {
    if (JSON.stringify(this.login) == JSON.stringify(this.localLogin)) {
      return;
    } else {
      this.localLogin = this.login;
    }
  }
}
